import React, {useEffect, useState} from 'react';
import Header from "./components/Header";
import Block1 from "./components/Block1";
import Block2 from "./components/Block2";
import Block3 from "./components/Block3";
import Block4 from "./components/Block4";
import {ParallaxProvider} from "react-scroll-parallax";

const App = () => {
    const [browser,setBrowser] = useState("");
    // function getBrowserId() {
    //     let aKeys = ["MSIE", "Firefox", "Safari", "Chrome", "Opera"], sUsrAg = navigator.userAgent,
    //         nIdx = aKeys.length - 1;
    //     for (nIdx; nIdx > -1 && sUsrAg.indexOf(aKeys[nIdx]) === -1; nIdx--) ;
    //     return setBrowser(nIdx);
    // }
    function getMobileOperatingSystem() {
        let userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return setBrowser("Windows Phone");
        }

        if (/android/i.test(userAgent)) {
            return setBrowser("Android");
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return setBrowser("iOS");
        }

        return "unknown";
    }
    useEffect(() => {
        // getBrowserId()
        if (window.innerWidth < 600){
            getMobileOperatingSystem()
        }
    }, []);

    return (
        <React.Fragment>
            <ParallaxProvider>
                <main className="main">
                    <Header/>
                    <Block1/>
                    <Block2 browser={browser}/>
                    <Block3 browser={browser}/>
                    <Block4/>
                </main>
            </ParallaxProvider>
        </React.Fragment>
    );
}

export default App;
