import React from "react";
import clsx from "clsx";
import MailIcon from "./icons/MailIcon";
import LocatedIcon from "./icons/LocatedIcon";
import SphereFooter from "./Parallax/SphereFooter";
import LogoIconFooter from "./icons/LogoIconFooter";

const Block4 = () => {
  return (
    <footer id="contacts" className={clsx("footer")}>
      <SphereFooter />
      <div className={clsx("footer__wrapper")}>
        <h3 className={clsx("footer__title  block__title")}>
          interested in working
        </h3>
        <h3 className={clsx("footer__title__with")}>with us?</h3>
        <div className={clsx("footer__subtitle__container")}>
          <p className={clsx("footer__subtitle")}>Contact Us now!</p>
          <p className={clsx("footer__subtitle__text")}>
            For Information, please contact via:
          </p>
        </div>
        <ul className={clsx("footer__list__container")}>
          <li className={clsx("footer__list")}>
            <div className={clsx("footer__mail__icon__container")}>
              <MailIcon />
            </div>
            <div className={clsx("footer__mail__container")}>
              <p className={clsx("footer__mail__subtitle")}>E-mail</p>
              <p className={clsx("footer__mail__text")}>
                oistrading1@gmail.com
              </p>
            </div>
          </li>
          <li className={clsx("footer__list footer__list__located")}>
            <div className={clsx("footer__located__icon__container")}>
              <LocatedIcon />
            </div>
            <div className={clsx("footer__located__container")}>
              <p className={clsx("footer__located__subtitle")}>Address</p>
              <p className={clsx("footer__located__text")}>
                Nibelungengasse 1-3/Top Nr. 39,{" "}
              </p>
              <p className={clsx("footer__located__text")}>
                1010 Vienna, Austria
              </p>
            </div>
          </li>
        </ul>
        <div className={clsx("footer__logo__container")}>
          <LogoIconFooter />
          <p className={clsx("footer__logo__text")}>OIS Trading</p>
        </div>
      </div>
    </footer>
  );
};

export default Block4;
