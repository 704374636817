import React from 'react';
import clsx from "clsx";
import HighQualityIcon from "./icons/HighQualityIcon";
import RocketIcon from "./icons/RocketIcon";
import WideIcon from "./icons/WideIcon";
import TypeAnimation from 'react-type-animation';
import {useInView} from "react-intersection-observer";
import Text from "../assets/textStroke/welcome to.png"

const Block2 = ({browser}) => {
    const {ref, inView} = useInView({
        threshold: 0,
        triggerOnce: true,
    });
    return (
        <section ref={ref} id="about" className={clsx("block2")}>
            <div className={clsx("block2__wrapper")}>
                <div className={clsx("block2__title__container")}>
                    {/*<div className={clsx("block2__title__bg")}>welcome to</div>*/}
                    {browser === "Android" ?
                        <div className={clsx("block2__img__text__container")}>
                            <img className={clsx("block2__img__text")} src={Text} alt="text stroke : welcome to"/>
                        </div>
                        :
                        <>
                            {inView &&
                                <TypeAnimation
                                    cursor={false}
                                    className={"block2__title__bg"}
                                    sequence={[
                                        1000,
                                        'welcome to',
                                    ]}
                                    wrapper="div"
                                    repeat={1}
                                />
                            }
                        </>
                    }
                    <h3 className={clsx("block2__title")}>OIS Trading</h3>
                </div>
                <div className={clsx("block2__list__container")}>
                    <div className={clsx("block2__list__wrapper")}>
                        <div className={clsx("block2__icon__container")}>
                            <HighQualityIcon/>
                        </div>
                        <h3 className={clsx("block2__list__title")}>High-quality</h3>
                        <div className={clsx("block2__text__container")}>
                            <p className={clsx("block2__text")}>High-quality products and perfect service. You can view
                                the range of products and be assured
                                of their quality.</p>
                            <p className={clsx("block2__text block2__text__margin")}>Our consultants will help you make
                                your choice, focus on
                                your needs and give all the advices,
                                tell you all the details about each product and provide with all documents confirming
                                high standards of production.</p>
                        </div>
                    </div>
                    <div className={clsx("block2__list__wrapper")}>
                        <div className={clsx("block2__icon__container")}>
                            <WideIcon/>
                        </div>
                        <h3 className={clsx("block2__list__title")}>wide range</h3>
                        <div className={clsx("block2__text__container")}>
                            <p className={clsx("block2__text")}>A wide range of products at competitive prices.</p>
                        </div>
                    </div>
                    <div className={clsx("block2__list__wrapper")}>
                        <div className={clsx("block2__icon__container")}>
                            <RocketIcon/>
                        </div>
                        <h3 className={clsx("block2__list__title")}>delivery</h3>
                        <div className={clsx("block2__text__container")}>
                            <p className={clsx("block2__text")}>You will be able to choose a preferred delivery method
                                and we, in turn, guarantee you a
                                fast delivery to any country, at any destination you choose as well as “door to door”
                                deliveries.</p>
                        </div>
                    </div>
                </div>
                <div className={clsx("block2__soars")}>
                    <div className={clsx("block2__soars__blue")}>
                        <p className={clsx("block2__soars__blue__text")}>Our <span
                            className="block2__soars__blue__text__span">employees are professionals </span> who will
                            support you at every stage 24/7.</p>
                    </div>
                    <div className={clsx("block2__soars__gray")}>
                        <p className={clsx("block2__soars__gray__text")}>We guarantee you a </p>
                        <p className={clsx("block2__soars__gray__text")}>fruitful cooperation.</p>
                    </div>
                </div>
            </div>

        </section>
    );
};

export default Block2;