import React from 'react';
import {Parallax} from "react-scroll-parallax";
import Sphere from "../../assets/sphere/sphereParallax1.png";

const SphereBlock3 = () => {
    return (
        <div className="sphere__block3">
            <Parallax speed={30}>
                <img  className="sphere__img__block3" src={Sphere} alt="sphere"/>
            </Parallax>
        </div>
    );
};

export default SphereBlock3;