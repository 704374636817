import React from 'react';
import clsx from "clsx";
import LogoIcon from "./icons/LogoIcon";
// import BurgerMenu from "./BurgerMenu";

const Header = () => {
    // const [menu,setMenu] = useState(false);
    // const handleMenu = () =>{
    //     setMenu(!menu)
    // };
    return (
        <header className={clsx("header")}>
            <div className={clsx("header__container")}>
                <div className={clsx("header__img__container")}>
                    <LogoIcon/>
                    <p className={clsx("header__img__text")}>OIS Trading</p>
                </div>
                {/*<ul className={clsx("header__ul")}>*/}
                    {/*<li className={clsx("header__list")}>*/}
                    {/*    <a className={clsx("header__list__link")} href="#">Home</a>*/}
                    {/*</li>*/}
                    {/*<li className={clsx("header__list")}>*/}
                    {/*    <a className={clsx("header__list__link")} href="#about">About</a>*/}
                    {/*</li>*/}
                    {/*<li className={clsx("header__list")}>*/}
                    {/*    <a className={clsx("header__list__link")} href="#products">Products</a>*/}
                    {/*</li>*/}
                    {/*<li className={clsx("header__list")}>*/}
                    {/*    <a className={clsx("header__list__link")} href="#contacts">Contacts</a>*/}
                    {/*</li>*/}
                {/*</ul>*/}
                {/*<BurgerMenu menu={menu} handleMenu={handleMenu}/>*/}
            </div>
        </header>
    );
};

export default Header;