import React from 'react';

const RocketIcon = () => {
    return (
        <svg width={window.innerWidth > 430 ? "46" : "30"} height={window.innerWidth > 430 ? "42" : "30"} viewBox="0 0 46 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.30769 29.7776C3.07692 32.1913 2 39.3559 2 39.3559C2 39.3559 10.0554 38.398 12.7692 35.5245C14.2985 33.9154 14.2769 31.4442 12.5754 29.95C11.7382 29.2393 10.6354 28.8286 9.47864 28.7968C8.32188 28.765 7.19266 29.1142 6.30769 29.7776V29.7776ZM22.4615 26.9041L16 21.1571C17.1462 18.5124 18.5894 15.9773 20.3077 13.5902C22.8173 10.0213 26.3118 7.08282 30.4588 5.05421C34.6057 3.02561 39.2673 1.97437 44 2.00047C44 7.21107 42.32 16.3679 31.0769 23.0727C28.3564 24.6028 25.4701 25.8864 22.4615 26.9041V26.9041Z"
                stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M15.9999 21.1571H5.23071C5.23071 21.1571 6.41533 15.3527 9.53841 13.4945C13.0276 11.4256 20.3076 13.4945 20.3076 13.4945M22.4615 26.9041V36.4824C22.4615 36.4824 28.9876 35.4288 31.0769 32.6511C33.403 29.5477 31.0769 23.0728 31.0769 23.0728"
                stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    );
};

export default RocketIcon;