import React from 'react';

const MailIcon = () => {
    return (
        <svg width={window.innerWidth > 600 ? "91" : "28"} height={window.innerWidth > 600 ? "61" : "20"} viewBox="0 0 91 61" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.94934 6.69425L40.8243 27.0407C42.0969 27.7224 43.7432 28.0456 45.3996 28.0456C47.056 28.0456 48.7023 27.7224 49.9749 27.0407L87.8499 6.69425C90.3194 5.3661 92.6525 0.199951 88.1226 0.199951H2.68169C-1.84816 0.199951 0.484944 5.3661 2.94934 6.69425ZM88.9963 17.8194L49.9749 38.1557C48.2579 39.0546 47.056 39.1607 45.3996 39.1607C43.7432 39.1607 42.5413 39.0546 40.8243 38.1557C39.1073 37.2568 4.80269 19.2738 1.99994 17.8144C0.0304447 16.7841 0.0506445 17.9911 0.0506445 18.9203V55.7499C0.0506445 57.8709 2.90894 60.7999 5.10064 60.7999H85.9006C88.0923 60.7999 90.9506 57.8709 90.9506 55.7499V18.9254C90.9506 17.9962 90.9708 16.7892 88.9963 17.8194Z"
                fill="#323B60"/>
        </svg>

    );
};

export default MailIcon;