import React from 'react';

const LogoIconFooter = () => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
             width={window.innerWidth > 600 ? "48.000000pt" : "48.000000pt"}
             height={window.innerWidth > 600 ? "55.000000pt" : "55.000000pt"}
             viewBox="0 0 130.000000 148.000000" preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,148.000000) scale(0.100000,-0.100000)"
               fill="#323B60" stroke="none">
                <path d="M510 1414 c-170 -44 -322 -168 -399 -324 -65 -133 -71 -184 -71 -607
0 -200 3 -363 8 -361 4 2 75 37 159 78 l152 75 3 259 3 259 68 28 67 28 0
-255 c0 -140 4 -254 8 -254 5 0 35 9 68 21 32 11 100 30 149 43 l90 23 5 258
5 259 25 6 c14 4 44 10 68 14 l42 6 0 -258 0 -258 88 12 c48 7 110 13 137 13
28 1 61 4 74 8 l23 6 -4 216 c-5 198 -7 222 -31 296 -66 202 -238 362 -439
410 -79 19 -226 18 -298 -1z"/>
                <path d="M1040 395 c-117 -16 -308 -59 -415 -92 -166 -52 -410 -162 -530 -237
l-40 -25 301 -1 301 0 46 35 c83 64 290 191 420 260 70 37 127 69 127 71 0 8
-116 2 -210 -11z"/>
            </g>
        </svg>
    );
};

export default LogoIconFooter;