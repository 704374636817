import React from 'react';
import clsx from "clsx";

const Block1 = () => {
    return (
        <section className={clsx("block1")}>
            <div className={clsx("block1__sphere__rotate")}>
                <div className={clsx("block1__sphere")}/>
            </div>
            <div className={clsx("block1__wrapper")}>
                <div className={clsx("block1__left__container")}>
                    <div className={clsx("block1__title__container")}>
                        <p className={clsx("block__title block1__title")}>OIS Trading</p>
                        <p className={clsx("block1__subtitle")}>GmbH</p>
                    </div>
                    <div className={clsx("block1__text__container")}>
                        <p className={clsx("block1__text")}>Austrian trading company and reliable supplier in European
                            market with a professional staff and great experience.</p>
                    </div>
                    <div className={clsx("block1__links__container")}>
                        <a className={clsx("block1__link__learn")} href="#about">Learn more</a>
                        <a className={clsx("block1__link__contacts")} href="#contacts">contacts</a>
                    </div>
                </div>
                <div className={clsx("block1__ois__container")}>
                    <p className={clsx("block1__ois__text")}>OIS</p>
                </div>
            </div>
            <div className={clsx("block1__bg")}/>
        </section>
    );
};

export default Block1;