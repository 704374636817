import React from 'react';
import {Parallax} from "react-scroll-parallax";
import Sphere from "../../assets/sphere/sphereParallax2.png";

const SphereFooter = () => {
    return (
        <div className="sphere__footer">
            <Parallax speed={20} >
                <img  className="sphere__img" src={Sphere} alt="sphere"/>
            </Parallax>
        </div>
    );
};

export default SphereFooter;