import React from 'react';

const WideIcon = () => {
    return (
        <svg width={window.innerWidth > 430 ? "35" : "30"} height={window.innerWidth > 430 ?    "40" : "30" } viewBox="0 0 35 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M33 37.5766L17.5 29.6707L2 37.5766V5.95295C2 4.90456 2.46658 3.89911 3.2971 3.15779C4.12762 2.41647 5.25404 2 6.42857 2H28.5714C29.746 2 30.8724 2.41647 31.7029 3.15779C32.5334 3.89911 33 4.90456 33 5.95295V37.5766Z"
                stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    );
};

export default WideIcon;