import React from 'react';

const HighQualityIcon = () => {
    return (
        <svg width="30" height={window.innerWidth > 430 ? "40" : "30"} viewBox="0 0 29 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.1168 23.5537C20.8087 23.5537 26.2336 18.7287 26.2336 12.7769C26.2336 6.82496 20.8087 2 14.1168 2C7.42488 2 2 6.82496 2 12.7769C2 18.7287 7.42488 23.5537 14.1168 23.5537Z"
                stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M21.1385 21.56L24.2141 37.9229L14.1168 32.5344L4.01947 37.9229L7.09512 21.56" stroke="white"
                  strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    );
};

export default HighQualityIcon;