import React from 'react';

const LocatedIcon = () => {
    return (
        <svg width={window.innerWidth > 600 ? "80" : "23"} height={window.innerWidth > 600 ? "102" : "28"}
             viewBox="0 0 80 102" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M40 102C40 102 79.75 65.7517 79.75 38.25C79.75 28.1055 75.5621 18.3764 68.1075 11.2032C60.6529 4.0299 50.5424 0 40 0C29.4576 0 19.3471 4.0299 11.8925 11.2032C4.43793 18.3764 0.25 28.1055 0.25 38.25C0.25 65.7517 40 102 40 102ZM40 57.375C34.7288 57.375 29.6735 55.3601 25.9463 51.7734C22.219 48.1868 20.125 43.3223 20.125 38.25C20.125 33.1777 22.219 28.3132 25.9463 24.7266C29.6735 21.1399 34.7288 19.125 40 19.125C45.2712 19.125 50.3265 21.1399 54.0537 24.7266C57.781 28.3132 59.875 33.1777 59.875 38.25C59.875 43.3223 57.781 48.1868 54.0537 51.7734C50.3265 55.3601 45.2712 57.375 40 57.375Z"
                fill="#323B60"/>
        </svg>

    );
};

export default LocatedIcon;