import React from 'react';
import clsx from "clsx";
import SphereBlock3 from "./Parallax/SphereBlock3";
import TypeAnimation from 'react-type-animation';
import {useInView} from "react-intersection-observer";
import Text from "../assets/textStroke/why choose us.png";

const Block3 = ({browser}) => {
    const {ref, inView} = useInView({
        threshold: 0,
        triggerOnce:true,
    });
    return (
        <section ref={ref} className={clsx("block3")}>
            <SphereBlock3/>
            <div className={clsx("block3__wrapper")}>
                <div className={clsx("block3__title__container")}>
                    {/*<div className={clsx("block3__title__bg")}>why choose us</div>*/}
                    {browser === "Android" ?
                        <div className={clsx("block3__img__text__container")}>
                            <img className={clsx("block3__img__text")} src={Text} alt="text stroke : why choose us"/>
                        </div>
                        :
                        <>
                            {inView &&
                                <TypeAnimation
                                    cursor={false}
                                    className={"block3__title__bg"}
                                    sequence={[
                                        1500,
                                        'why choose us',
                                    ]}
                                    wrapper="div"
                                    repeat={1}
                                />
                            }
                        </>
                    }
                    <h3 className={clsx("block3__title block__title")}>why choose us</h3>
                </div>
                <div className={clsx("block3__list__container")}>
                    <div className={clsx("block3__list block3__list1")}>
                      <p className={clsx("block3__list__numb")}>01</p>
                        <div className={clsx("block3__list__dash")}/>
                        <p className={clsx("block3__list__text")}>Wide range of high-quality products</p>
                    </div>
                    <div className={clsx("block3__list__wrapper")}>
                        <div className={clsx("block3__list block3__list2")}>
                             <div className={clsx("svg__numb2")}>
                          <p className={clsx("block3__list__numb")}>02</p>
                            </div>
                            <div className={clsx("block3__list__dash block3__list__dash2")}/>
                            <p className={clsx("block3__list__text block3__list__text2")}>Perfect service</p>
                        </div>
                        <div className={clsx("block3__list block3__list3")}>
                           <p className={clsx("block3__list__numb")}>03</p>
                            <div className={clsx("block3__list__dash")}/>
                            <p className={clsx("block3__list__text")}>Competitive prices</p>
                        </div>
                        <div className={clsx("block3__list block3__list4")}>
                            <div className={clsx("svg__numb4")}>
                               <p className={clsx("block3__list__numb")}>04</p>
                            </div>
                            <div className={clsx("block3__list__dash block3__list__dash4")}/>
                            <p className={clsx("block3__list__text block3__list__text4")}>“Door to door” delivery</p>
                        </div>
                        <div className={clsx("block3__list block3__list5")}>
                            <p className={clsx("block3__list__numb")}>05</p>
                            <div className={clsx("block3__list__dash")}/>
                            <p className={clsx("block3__list__text")}>Support 24/7</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};


export default Block3;